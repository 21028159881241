import { createSlice } from '@reduxjs/toolkit';

import {
  createBook,
  editBook,
  getBookByID,
  getBooksList,
  removeBook,
  removeBookPage,
  saveBookPage
} from '_api/books';

const initialState = {
  // Get Books
  booksList: [],
  booksListMeta: {},
  currentBookData: {},
  getBooksLoading: false,
  getBooksSuccess: false,
  getBooksError: false,
  // Create Book
  createBook: {
    createBookLoading: false,
    createBookSuccess: false,
    createBookError: false
  },
  // Save Book Page
  saveBookPage: {
    saveBookPageLoading: false,
    saveBookPageSuccess: false,
    saveBookPageError: false
  },
  // Remove Book Page
  removeBookPage: {
    removeBookPageLoading: false,
    removeBookPageSuccess: false,
    removeBookPageError: false
  },
  // Edit Book
  editBook: {
    editBookLoading: false,
    editBookSuccess: false,
    editBookError: false
  },
  // Remove Book
  removeBook: {
    removeBookLoading: false,
    removeBookSuccess: false,
    removeBookError: false
  }
};

const books = createSlice({
  name: 'books',
  initialState,
  reducers: {
    resetCreateBookData: state => ({
      ...state,
      createBook: {
        createBookLoading: false,
        createBookSuccess: false,
        createBookError: false
      }
    }),
    resetEditBookData: state => ({
      ...state,
      editBook: {
        editBookLoading: false,
        editBookSuccess: false,
        editBookError: false
      }
    }),
    resetDeleteBookData: state => ({
      ...state,
      removeBook: {
        removeBookLoading: false,
        removeBookSuccess: false,
        removeBookError: false
      }
    }),
    resetBooksListData: state => ({
      ...state,
      booksList: [],
      booksListMeta: {},
      getBooksLoading: false,
      getBooksSuccess: false,
      getBooksError: false
    }),
    resetCurrentBookData: state => ({
      ...state,
      currentBookData: {},
      getBooksLoading: false,
      getBooksSuccess: false,
      getBooksError: false
    }),
    setCurrentBookData: (state, action) => ({
      ...state,
      currentBookData: action.payload ?? {}
    })
  },
  extraReducers: {
    // Get books
    [getBooksList.pending]: state => {
      return {
        ...state,
        booksList: null,
        booksListMeta: {},
        getBooksLoading: true,
        getBooksSuccess: false,
        getBooksError: false
      };
    },
    [getBooksList.fulfilled]: (state, action) => {
      const { data: booksList = null, meta } = action.payload ?? {};

      return {
        ...state,
        booksList,
        booksListMeta: meta,
        getBooksLoading: false,
        getBooksSuccess: true,
        getBooksError: false
      };
    },
    [getBooksList.rejected]: state => {
      return {
        ...state,
        getBooksLoading: false,
        getBooksSuccess: false,
        getBooksError: true
      };
    },
    // Get book By ID
    [getBookByID.pending]: state => {
      return {
        ...state,
        getBooksLoading: true,
        getBooksSuccess: false,
        getBooksError: false
      };
    },
    [getBookByID.fulfilled]: (state, action) => {
      const { data: currentBookData = null } = action.payload ?? {};

      return {
        ...state,
        currentBookData,
        getBooksLoading: false,
        getBooksSuccess: true,
        getBooksError: false
      };
    },
    [getBookByID.rejected]: state => {
      return {
        ...state,
        getBooksLoading: false,
        getBooksSuccess: false,
        getBooksError: true
      };
    },
    // Create book
    [createBook.pending]: state => ({
      ...state,
      createBook: {
        createBookLoading: true,
        createBookSuccess: false,
        createBookError: false
      }
    }),
    [createBook.fulfilled]: (state, action) => {
      const { id } = action.payload ?? {};

      return {
        ...state,
        createBook: {
          createBookLoading: false,
          createBookSuccess: true,
          createBookError: false
        },
        currentBookData: {
          ...state.currentBookData,
          id
        }
      };
    },
    [createBook.rejected]: (state, action) => {
      return {
        ...state,
        createBook: {
          createBookLoading: false,
          createBookSuccess: false,
          createBookError: action.payload?.details?.code ?? 0
        }
      };
    },
    // Save book page
    [saveBookPage.pending]: state => ({
      ...state,
      saveBookPage: {
        saveBookPageLoading: true,
        saveBookPageSuccess: false,
        saveBookPageError: false
      }
    }),
    [saveBookPage.fulfilled]: state => {
      return {
        ...state,
        saveBookPage: {
          saveBookPageLoading: false,
          saveBookPageSuccess: true,
          saveBookPageError: false
        }
      };
    },
    [saveBookPage.rejected]: (state, action) => {
      return {
        ...state,
        saveBookPage: {
          saveBookPageLoading: false,
          saveBookPageSuccess: false,
          saveBookPageError: action.payload?.details?.code ?? 0
        }
      };
    },
    // Save book page
    [removeBookPage.pending]: state => ({
      ...state,
      removeBookPage: {
        removeBookPageLoading: true,
        removeBookPageSuccess: false,
        removeBookPageError: false
      }
    }),
    [removeBookPage.fulfilled]: state => {
      return {
        ...state,
        removeBookPage: {
          removeBookPageLoading: false,
          removeBookPageSuccess: true,
          removeBookPageError: false
        }
      };
    },
    [removeBookPage.rejected]: (state, action) => {
      return {
        ...state,
        removeBookPage: {
          removeBookPageLoading: false,
          removeBookPageSuccess: false,
          removeBookPageError: action.payload?.details?.code ?? 0
        }
      };
    },
    // edit book
    [editBook.pending]: state => ({
      ...state,
      editBook: {
        editBookLoading: true,
        editBookSuccess: false,
        editBookError: false
      }
    }),
    [editBook.fulfilled]: state => ({
      ...state,
      editBook: {
        editBookLoading: false,
        editBookSuccess: true,
        editBookError: false
      }
    }),
    [editBook.rejected]: (state, action) => ({
      ...state,
      editBook: {
        editBookLoading: false,
        editBookSuccess: false,
        editBookError: action.payload?.details?.code ?? 0
      }
    }),
    // remove book
    [removeBook.pending]: state => ({
      ...state,
      removeBook: {
        removeBookLoading: true,
        removeBookSuccess: false,
        removeBookError: false
      }
    }),
    [removeBook.fulfilled]: state => ({
      ...state,
      removeBook: {
        removeBookLoading: false,
        removeBookSuccess: true,
        removeBookError: false
      }
    }),
    [removeBook.rejected]: (state, action) => ({
      ...state,
      removeBook: {
        removeBookLoading: false,
        removeBookSuccess: false,
        removeBookError: action.payload?.details?.code ?? 0
      }
    })
  }
});

export const {
  resetCurrentBookData,
  resetCreateBookData,
  resetEditBookData,
  resetDeleteBookData,
  setCurrentBookData
} = books.actions;

export default books.reducer;
