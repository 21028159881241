import React, { useEffect, useMemo, useState } from 'react';
import { dispatch } from 'store';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { noop } from 'lodash';
import { Box, Button, Drawer, FormLabel, Grid, Switch, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { createVocabularyItem, editVocabularyItem } from '_api/vocabulary';
import { getCategoriesList } from '_api/categories';
import { getSeriesList } from '_api/series';
import { getFileURL } from 'utils/fileUtils';
import TextField from 'components/CustomFormItem/TextField';
import BookSelectionField from 'components/CustomFormItem/BookSelectionField';
import { fieldTypes } from 'components/CustomFormItem/constants';
import VisuallyHiddenInput from 'components/CustomFormItem/VisuallyHiddenInput';
import { resetCurrentVocabularyItemData } from 'store/reducers/vocabulary';

import useVocabularyItemForm from './includes/useVocabularyItemForm';
import { toast } from 'react-toastify';

const AddEditVocabularyItemDrawer = ({
  isDrawerOpen,
  toggleDrawer,
  vocabularyItemInitialData,
  getVocabularyItemsList
}) => {
  const {
    createVocabularyItem: { createVocabularyItemLoading = false } = {},
    editVocabularyItem: { editVocabularyItemLoading = false } = {},
    removeVocabularyItem: { removeVocabularyItemLoading = false } = {}
  } = useSelector(state => state.vocabulary ?? {});
  const { booksList = [] } = useSelector(state => state.books ?? {});
  const [isDrawerExpanded, setIsDrawerExpanded] = useState(false);
  const handleSubmitVocabularyItem = async vocabularyItemDataObj => {
    const vocabularyItemData = structuredClone(vocabularyItemDataObj);

    if (mandarinPresent && !vocabularyItemData.audioMnd.name) {
      toast.error('Validation error. Audio (Mandarin) field is required.');
      return;
    }

    if (vocabularyItemData?.id) {
      if (!(vocabularyItemData.image instanceof File)) {
        console.log('image is null');
        vocabularyItemData.image = null;
      }

      if (!(vocabularyItemData.audio instanceof File)) {
        vocabularyItemData.audio = null;
      }

      if (!(vocabularyItemData.audioMnd instanceof File)) {
        vocabularyItemData.audioMnd = null;
      }

      dispatch(
        editVocabularyItem({
          vocabularyItemData,
          vocabularyItemID: vocabularyItemData.id
        })
      );
    } else {
      dispatch(createVocabularyItem({ vocabularyItemData }));
    }
  };

  const vocabularyItemForm = useVocabularyItemForm({
    vocabularyItemInitialData: {
      showInQuiz: false,
      showInPage: false,
      ...vocabularyItemInitialData,
      image: {
        name: vocabularyItemInitialData?.image
      },
      audio: {
        name: vocabularyItemInitialData?.audio
      },
      audioMnd: {
        name: vocabularyItemInitialData?.audioMnd
      }
    },
    toggleDrawer,
    getVocabularyItemsList,
    submitVocabularyItem: handleSubmitVocabularyItem
  });

  const { mandarinPresent = false } = useMemo(
    () => booksList?.find(({ id }) => id === vocabularyItemForm.values?.bookId) ?? {},
    [vocabularyItemForm.values, booksList]
  );

  const isEditMode = !!vocabularyItemInitialData?.id;

  useEffect(() => {
    dispatch(getCategoriesList());
    dispatch(getSeriesList());
  }, []);

  useEffect(() => {
    if (!isDrawerOpen) {
      setIsDrawerExpanded(false);
      dispatch(resetCurrentVocabularyItemData());
    }
  }, [isDrawerOpen]);

  return (
    <>
      <Drawer
        variant="temporary"
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        sx={{
          zIndex: '1300',
          '& > .MuiPaper-root': { width: isDrawerExpanded ? '100%' : 500 }
        }}
      >
        <Box display="flex" flexWrap="wrap" width="100%">
          <Box px="16px" pb="24px" width="100%">
            <Grid
              container
              display="flex"
              justifyContent="end"
              position="sticky"
              top={0}
              bgcolor="#fff"
              zIndex={100}
              py={2}
            >
              <Grid item xs={12}>
                <Box display="flex" gap="16px" width="100%" justifyContent="end">
                  <Button
                    variant="contained"
                    color="success"
                    sx={{ px: 2 }}
                    onClick={vocabularyItemForm.handleSubmit}
                    disabled={
                      createVocabularyItemLoading ||
                      editVocabularyItemLoading ||
                      removeVocabularyItemLoading
                    }
                  >
                    {isEditMode ? 'Save' : 'Create'}
                  </Button>
                  <Button variant="outlined" color="inherit" onClick={toggleDrawer}>
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              spacing="16px"
              sx={{
                flexDirection: isDrawerExpanded ? 'row-reverse' : 'initial',
                alignItems: 'start'
              }}
            >
              <Grid item md={isDrawerExpanded ? 6 : 12} sm={12} xs={12}>
                <Grid
                  container
                  direction={isDrawerExpanded ? 'column-reverse' : 'column'}
                  spacing="16px"
                >
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <TextField
                          name="Text *"
                          code="text"
                          fullWidth
                          isMultiLine
                          maxLength={160}
                          value={vocabularyItemForm.values.text}
                          onValueChange={vocabularyItemForm.handleChange}
                          field_type={fieldTypes.TEXT}
                          formObject={vocabularyItemForm}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <BookSelectionField
                          fieldCode="bookId"
                          formObject={vocabularyItemForm}
                          fullWidth
                          displayEmpty={false}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Box width="100%" display="flex" flexDirection="column" alignItems="start">
                          <Box
                            width="100%"
                            display="flex"
                            alignItems="flex-end"
                            justifyContent="space-between"
                          >
                            <FormLabel
                              component="p"
                              sx={{ fontSize: 14, fontWeight: 700, mb: 1, color: '#8C8C8C' }}
                            >
                              Image *
                            </FormLabel>
                          </Box>
                          <Button
                            component="label"
                            variant="outlined"
                            color="secondary"
                            tabIndex={-1}
                            startIcon={<CloudUploadIcon />}
                          >
                            Upload file
                            <VisuallyHiddenInput
                              type="file"
                              onChange={event =>
                                vocabularyItemForm.setFieldValue('image', event.target.files[0])
                              }
                            />
                          </Button>
                          {!!vocabularyItemForm.values.image && (
                            <Typography
                              component="a"
                              href={getFileURL(vocabularyItemForm.values.image)}
                              target="_blank"
                              rel="noopener noreferrer"
                              sx={{
                                pt: 1,
                                fontSize: 14,
                                fontWeight: 400,
                                mb: 1,
                                color: '#222',
                                wordBreak: 'break-all'
                              }}
                            >
                              {vocabularyItemForm.values.image?.name}
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box width="100%" display="flex" flexDirection="column" alignItems="start">
                          <Box
                            width="100%"
                            display="flex"
                            alignItems="flex-end"
                            justifyContent="space-between"
                          >
                            <FormLabel
                              component="p"
                              sx={{ fontSize: 14, fontWeight: 700, mb: 1, color: '#8C8C8C' }}
                            >
                              Audio (Cantonese) *
                            </FormLabel>
                          </Box>
                          <Button
                            component="label"
                            variant="outlined"
                            color="secondary"
                            tabIndex={-1}
                            startIcon={<CloudUploadIcon />}
                          >
                            Upload file
                            <VisuallyHiddenInput
                              type="file"
                              onChange={event =>
                                vocabularyItemForm.setFieldValue('audio', event.target.files[0])
                              }
                            />
                          </Button>
                          {!!vocabularyItemForm.values.audio && (
                            <Typography
                              component="a"
                              href={getFileURL(vocabularyItemForm.values.audio)}
                              target="_blank"
                              rel="noopener noreferrer"
                              sx={{
                                pt: 1,
                                fontSize: 14,
                                fontWeight: 400,
                                mb: 1,
                                color: '#222',
                                wordBreak: 'break-all'
                              }}
                            >
                              {vocabularyItemForm.values.audio?.name}
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box width="100%" display="flex" flexDirection="column" alignItems="start">
                          <Box
                            width="100%"
                            display="flex"
                            alignItems="flex-end"
                            justifyContent="space-between"
                          >
                            <FormLabel
                              component="p"
                              sx={{ fontSize: 14, fontWeight: 700, mb: 1, color: '#8C8C8C' }}
                            >
                              Audio (Mandarin) {mandarinPresent ? '*' : ''}
                            </FormLabel>
                          </Box>
                          <Button
                            component="label"
                            variant="outlined"
                            color="secondary"
                            tabIndex={-1}
                            startIcon={<CloudUploadIcon />}
                          >
                            Upload file
                            <VisuallyHiddenInput
                              type="file"
                              onChange={event =>
                                vocabularyItemForm.setFieldValue('audioMnd', event.target.files[0])
                              }
                            />
                          </Button>
                          {!!vocabularyItemForm.values.audioMnd && (
                            <Typography
                              component="a"
                              href={getFileURL(vocabularyItemForm.values.audioMnd)}
                              target="_blank"
                              rel="noopener noreferrer"
                              sx={{
                                pt: 1,
                                fontSize: 14,
                                fontWeight: 400,
                                mb: 1,
                                color: '#222',
                                wordBreak: 'break-all'
                              }}
                            >
                              {vocabularyItemForm.values.audioMnd?.name}
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box width="100%" display="flex" flexDirection="column" alignItems="start">
                          <Box
                            width="100%"
                            display="flex"
                            alignItems="flex-end"
                            justifyContent="space-between"
                          >
                            <FormLabel
                              component="p"
                              sx={{
                                fontSize: 14,
                                fontWeight: 700,
                                mb: 1,
                                color: '#8C8C8C'
                              }}
                            >
                              Show In Vocabulary
                            </FormLabel>
                          </Box>
                          <Switch
                            name="showInPage"
                            checked={vocabularyItemForm.values.showInPage}
                            onChange={event =>
                              vocabularyItemForm.setFieldValue('showInPage', event.target.checked)
                            }
                            color="success"
                            sx={{ ml: -1.5 }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box width="100%" display="flex" flexDirection="column" alignItems="start">
                          <Box
                            width="100%"
                            display="flex"
                            alignItems="flex-end"
                            justifyContent="space-between"
                          >
                            <FormLabel
                              component="p"
                              sx={{
                                fontSize: 14,
                                fontWeight: 700,
                                mb: 1,
                                color: '#8C8C8C'
                              }}
                            >
                              Show In Quiz
                            </FormLabel>
                          </Box>
                          <Switch
                            name="showInQuiz"
                            checked={vocabularyItemForm.values.showInQuiz}
                            onChange={event =>
                              vocabularyItemForm.setFieldValue('showInQuiz', event.target.checked)
                            }
                            color="success"
                            sx={{ ml: -1.5 }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

const propTypes = {
  isDrawerOpen: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  getVocabularyItemsList: PropTypes.func,
  vocabularyItemInitialData: PropTypes.object
};

const defaultProps = {
  isDrawerOpen: false,
  toggleDrawer: noop,
  getVocabularyItemsList: noop,
  vocabularyItemInitialData: null
};

AddEditVocabularyItemDrawer.propTypes = propTypes;
AddEditVocabularyItemDrawer.defaultProps = defaultProps;

export default AddEditVocabularyItemDrawer;
