export const getVocabularyTableDefaultColumns = (booksList = []) => [
  {
    field: 'text',
    headerName: 'Text',
    flex: 1,
    type: 'string',
    filterable: true,
    sortable: true
  },
  {
    field: 'bookId',
    headerName: 'Book Name',
    flex: 1,
    type: 'string',
    filterable: true,
    sortable: true,
    valueGetter: params => {
      const book = booksList?.find(({ id }) => id === params.row.bookId) ?? {};
      return book?.title ?? 'N/A';
    }
  },
  {
    field: 'showInPage',
    headerName: 'Show In Vocabulary Page',
    flex: 1,
    type: 'boolean',
    filterable: true,
    sortable: true
  },
  {
    field: 'showInQuiz',
    headerName: 'Show In Quiz',
    flex: 1,
    type: 'boolean',
    filterable: true,
    sortable: true
  },
  {
    field: 'createdAt',
    headerName: 'Created Date',
    flex: 1,
    type: 'date',
    filterable: true,
    sortable: true,
    valueGetter: params => {
      return new Date(params.row.createdAt);
    }
  },
  {
    field: 'updatedAt',
    headerName: 'Modified Date',
    flex: 1,
    type: 'date',
    filterable: true,
    sortable: true,
    valueGetter: params => {
      return new Date(params.row.updatedAt);
    }
  }
];
