import { createSlice } from '@reduxjs/toolkit';

import { createSeries, getSeriesList } from '_api/series';

const initialState = {
  // Get Series
  seriesList: [],
  seriesListMeta: {},
  getSeriesLoading: false,
  getSeriesSuccess: false,
  getSeriesError: false,
  // Create Series
  createSeries: {
    createSeriesLoading: false,
    createSeriesSuccess: false,
    createSeriesError: false
  }
};

const series = createSlice({
  name: 'series',
  initialState,
  reducers: {
    resetCreateSeriesData: state => ({
      ...state,
      createSeries: {
        createSeriesLoading: false,
        createSeriesSuccess: false,
        createSeriesError: false
      }
    }),
    resetSeriesListData: state => ({
      ...state,
      seriesList: [],
      seriesListMeta: {},
      getSeriesLoading: false,
      getSeriesSuccess: false,
      getSeriesError: false
    })
  },
  extraReducers: {
    // Get series
    [getSeriesList.pending]: state => {
      return {
        ...state,
        seriesList: null,
        seriesListMeta: {},
        getSeriesLoading: true,
        getSeriesSuccess: false,
        getSeriesError: false
      };
    },
    [getSeriesList.fulfilled]: (state, action) => {
      const { data: seriesList = null, meta } = action.payload ?? {};

      return {
        ...state,
        seriesList,
        seriesListMeta: meta,
        getSeriesLoading: false,
        getSeriesSuccess: true,
        getSeriesError: false
      };
    },
    [getSeriesList.rejected]: state => {
      return {
        ...state,
        getSeriesLoading: false,
        getSeriesSuccess: false,
        getSeriesError: true
      };
    },
    // Create series
    [createSeries.pending]: state => ({
      ...state,
      createSeries: {
        createSeriesLoading: true,
        createSeriesSuccess: false,
        createSeriesError: false
      }
    }),
    [createSeries.fulfilled]: state => {
      return {
        ...state,
        createSeries: {
          createSeriesLoading: false,
          createSeriesSuccess: true,
          createSeriesError: false
        }
      };
    },
    [createSeries.rejected]: state => {
      return {
        ...state,
        createSeries: {
          createSeriesLoading: false,
          createSeriesSuccess: false,
          createSeriesError: true
        }
      };
    }
  }
});

export const { resetCurrentSeriesData, resetCreateSeriesData } = series.actions;

export default series.reducer;
