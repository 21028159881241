import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'utils/api';

export const getUsersList = createAsyncThunk(
  'users/getUsersList',
  async (_, { rejectWithValue }) => {
    try {
      return await axiosInstance.get(`/users`, {
        data: {}
      });
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
