import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'utils/api';

export const getCategoriesList = createAsyncThunk(
  'categories/getCategoriesList',
  async (_, { rejectWithValue }) => {
    try {
      return await axiosInstance.get(`/categories`, {
        data: {}
      });
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createCategory = createAsyncThunk(
  'categories/createCategory',
  async ({ categoryData = {} }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/categories`, categoryData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
