import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Navigate } from 'react-router-dom';

import authStorage from 'store/authStorage';
import { dispatch } from 'store';
import { getAuthenticatedUser } from 'store/reducers/auth';

const PrivateRoute = ({ children }) => {
  const { accessToken } = authStorage.get();

  useEffect(() => {
    if (accessToken) {
      dispatch(getAuthenticatedUser());
    }
    // eslint-disable-next-line
  }, []);

  if (accessToken) return <>{children}</>;

  return <Navigate to="/login" />;
};

PrivateRoute.propTypes = {
  children: PropTypes.node
};

export default React.memo(PrivateRoute);
