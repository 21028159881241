import React from 'react';
import PropTypes from 'prop-types';

// custom import
import { Typography } from '@mui/material';
import { get } from 'lodash';

const ErrorHandler = ({ isCustomHandler, isAddableItem, formObject, code, index }) => {
  // rendering
  if (isCustomHandler) {
    if (get(formObject?.errors, `${code}.value`)) {
      return (
        <Typography variant="p" color="error" fontSize={14} fontWeight={700}>
          {get(formObject?.errors, `${code}.value`)}
        </Typography>
      );
    }
  } else if (isAddableItem) {
    if (get(formObject?.errors, `${code}.options[${index}].value`)) {
      return (
        <Typography variant="p" color="error" fontSize={14} fontWeight={700}>
          {get(formObject?.errors, `${code}.options[${index}].value`)}
        </Typography>
      );
    }
  } else if (get(formObject?.errors, code)) {
    return (
      <Typography variant="p" color="error" fontSize={14} fontWeight={700}>
        {get(formObject?.errors, code)}
      </Typography>
    );
  }

  return null;
};

ErrorHandler.propTypes = {
  isCustomHandler: PropTypes.bool,
  isAddableItem: PropTypes.bool,
  formObject: PropTypes.object,
  code: PropTypes.string,
  index: PropTypes.number
};

ErrorHandler.defaultProps = {
  isCustomHandler: false,
  isAddableItem: false,
  formObject: {},
  code: '',
  index: 0
};
export default React.memo(ErrorHandler);
