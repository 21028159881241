import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'utils/api';

export const getVocabularyItemsList = createAsyncThunk(
  'vocabulary/getVocabularyItemsList',
  async (_, { rejectWithValue }) => {
    try {
      return await axiosInstance.get(`/vocabularies`, {
        params: {
          limit: 1000
        }
      });
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createVocabularyItem = createAsyncThunk(
  'vocabulary/createVocabularyItem',
  async ({ vocabularyItemData = {} }, { rejectWithValue }) => {
    try {
      const { bookId } = vocabularyItemData;
      const response = await axiosInstance.post(`/books/${bookId}/vocabulary`, vocabularyItemData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const editVocabularyItem = createAsyncThunk(
  'vocabulary/editVocabularyItem',
  async ({ vocabularyItemData = {}, vocabularyItemID = '' }, { rejectWithValue }) => {
    try {
      const { bookId } = vocabularyItemData;

      const response = await axiosInstance.post(
        `/books/${bookId}/vocabulary/${vocabularyItemID}`,
        vocabularyItemData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const removeVocabularyItem = createAsyncThunk(
  'vocabulary/removeVocabularyItem',
  async ({ vocabularyItemID = '' }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/vocabularies/${vocabularyItemID}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
