export default {
  set: data => {
    const { accessToken, tokenType, refreshToken } = data;
    accessToken && localStorage.setItem('accessToken', accessToken);
    tokenType && localStorage.setItem('tokenType', tokenType);
    refreshToken && localStorage.setItem('refreshToken', refreshToken);
  },
  get: () => ({
    accessToken: localStorage.getItem('accessToken'),
    tokenType: localStorage.getItem('tokenType'),
    refreshToken: localStorage.getItem('refreshToken')
  }),
  remove: () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('tokenType');
    localStorage.removeItem('refreshToken');
  },
  clear: () => {
    localStorage.clear();
  }
};
