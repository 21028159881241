import { createSlice } from '@reduxjs/toolkit';

import {
  createVocabularyItem,
  editVocabularyItem,
  getVocabularyItemsList,
  removeVocabularyItem
} from '_api/vocabulary';

const initialState = {
  // Get Vocabulary Items
  vocabularyItemsList: [],
  vocabularyItemsListMeta: {},
  currentVocabularyItemData: {},
  getVocabularyItemsLoading: false,
  getVocabularyItemsSuccess: false,
  getVocabularyItemsError: false,
  // Create Vocabulary Item
  createVocabularyItem: {
    createVocabularyItemLoading: false,
    createVocabularyItemSuccess: false,
    createVocabularyItemError: false
  },
  // Edit Vocabulary Item
  editVocabularyItem: {
    editVocabularyItemLoading: false,
    editVocabularyItemSuccess: false,
    editVocabularyItemError: false
  },
  // Remove Vocabulary Item
  removeVocabularyItem: {
    removeVocabularyItemLoading: false,
    removeVocabularyItemSuccess: false,
    removeVocabularyItemError: false
  }
};

const vocabulary = createSlice({
  name: 'vocabulary',
  initialState,
  reducers: {
    resetCreateVocabularyItemData: state => ({
      ...state,
      createVocabularyItem: {
        createVocabularyItemLoading: false,
        createVocabularyItemSuccess: false,
        createVocabularyItemError: false
      }
    }),
    resetEditVocabularyItemData: state => ({
      ...state,
      editVocabularyItem: {
        editVocabularyItemLoading: false,
        editVocabularyItemSuccess: false,
        editVocabularyItemError: false
      }
    }),
    resetDeleteVocabularyItemData: state => ({
      ...state,
      removeVocabularyItem: {
        removeVocabularyItemLoading: false,
        removeVocabularyItemSuccess: false,
        removeVocabularyItemError: false
      }
    }),
    resetVocabularyItemsListData: state => ({
      ...state,
      vocabularyItemsList: [],
      vocabularyItemsListMeta: {},
      getVocabularyItemsLoading: false,
      getVocabularyItemsSuccess: false,
      getVocabularyItemsError: false
    }),
    resetCurrentVocabularyItemData: state => ({
      ...state,
      currentVocabularyItemData: {},
      getVocabularyItemsLoading: false,
      getVocabularyItemsSuccess: false,
      getVocabularyItemsError: false
    })
  },
  extraReducers: {
    // Get vocabularyItems
    [getVocabularyItemsList.pending]: state => {
      return {
        ...state,
        vocabularyItemsList: null,
        vocabularyItemsListMeta: {},
        getVocabularyItemsLoading: true,
        getVocabularyItemsSuccess: false,
        getVocabularyItemsError: false
      };
    },
    [getVocabularyItemsList.fulfilled]: (state, action) => {
      const { data: vocabularyItemsList = null, meta } = action.payload ?? {};

      return {
        ...state,
        vocabularyItemsList,
        vocabularyItemsListMeta: meta,
        getVocabularyItemsLoading: false,
        getVocabularyItemsSuccess: true,
        getVocabularyItemsError: false
      };
    },
    [getVocabularyItemsList.rejected]: state => {
      return {
        ...state,
        getVocabularyItemsLoading: false,
        getVocabularyItemsSuccess: false,
        getVocabularyItemsError: true
      };
    },
    // Create vocabularyItem
    [createVocabularyItem.pending]: state => ({
      ...state,
      createVocabularyItem: {
        createVocabularyItemLoading: true,
        createVocabularyItemSuccess: false,
        createVocabularyItemError: false
      }
    }),
    [createVocabularyItem.fulfilled]: state => {
      return {
        ...state,
        createVocabularyItem: {
          createVocabularyItemLoading: false,
          createVocabularyItemSuccess: true,
          createVocabularyItemError: false
        }
      };
    },
    [createVocabularyItem.rejected]: state => {
      return {
        ...state,
        createVocabularyItem: {
          createVocabularyItemLoading: false,
          createVocabularyItemSuccess: false,
          createVocabularyItemError: true
        }
      };
    },
    // edit vocabularyItem
    [editVocabularyItem.pending]: state => ({
      ...state,
      editVocabularyItem: {
        editVocabularyItemLoading: true,
        editVocabularyItemSuccess: false,
        editVocabularyItemError: false
      }
    }),
    [editVocabularyItem.fulfilled]: state => ({
      ...state,
      editVocabularyItem: {
        editVocabularyItemLoading: false,
        editVocabularyItemSuccess: true,
        editVocabularyItemError: false
      }
    }),
    [editVocabularyItem.rejected]: state => ({
      ...state,
      editVocabularyItem: {
        editVocabularyItemLoading: false,
        editVocabularyItemSuccess: false,
        editVocabularyItemError: true
      }
    }),
    // remove vocabularyItem
    [removeVocabularyItem.pending]: state => ({
      ...state,
      removeVocabularyItem: {
        removeVocabularyItemLoading: true,
        removeVocabularyItemSuccess: false,
        removeVocabularyItemError: false
      }
    }),
    [removeVocabularyItem.fulfilled]: state => ({
      ...state,
      removeVocabularyItem: {
        removeVocabularyItemLoading: false,
        removeVocabularyItemSuccess: true,
        removeVocabularyItemError: false
      }
    }),
    [removeVocabularyItem.rejected]: state => ({
      ...state,
      removeVocabularyItem: {
        removeVocabularyItemLoading: false,
        removeVocabularyItemSuccess: false,
        removeVocabularyItemError: true
      }
    })
  }
});

export const {
  resetCurrentVocabularyItemData,
  resetCreateVocabularyItemData,
  resetEditVocabularyItemData,
  resetDeleteVocabularyItemData
} = vocabulary.actions;

export default vocabulary.reducer;
