import { createSlice } from '@reduxjs/toolkit';

import { createCategory, getCategoriesList } from '_api/categories';

const initialState = {
  // Get Categories
  categoriesList: [],
  categoriesListMeta: {},
  getCategoriesLoading: false,
  getCategoriesSuccess: false,
  getCategoriesError: false,
  // Create Category
  createCategory: {
    createCategoryLoading: false,
    createCategorySuccess: false,
    createCategoryError: false
  }
};

const categories = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    resetCreateCategoryData: state => ({
      ...state,
      createCategory: {
        createCategoryLoading: false,
        createCategorySuccess: false,
        createCategoryError: false
      }
    }),
    resetCategoriesListData: state => ({
      ...state,
      categoriesList: [],
      categoriesListMeta: {},
      getCategoriesLoading: false,
      getCategoriesSuccess: false,
      getCategoriesError: false
    })
  },
  extraReducers: {
    // Get categories
    [getCategoriesList.pending]: state => {
      return {
        ...state,
        categoriesList: null,
        categoriesListMeta: {},
        getCategoriesLoading: true,
        getCategoriesSuccess: false,
        getCategoriesError: false
      };
    },
    [getCategoriesList.fulfilled]: (state, action) => {
      const { data: categoriesList = null, meta } = action.payload ?? {};

      return {
        ...state,
        categoriesList,
        categoriesListMeta: meta,
        getCategoriesLoading: false,
        getCategoriesSuccess: true,
        getCategoriesError: false
      };
    },
    [getCategoriesList.rejected]: state => {
      return {
        ...state,
        getCategoriesLoading: false,
        getCategoriesSuccess: false,
        getCategoriesError: true
      };
    },
    // Create category
    [createCategory.pending]: state => ({
      ...state,
      createCategory: {
        createCategoryLoading: true,
        createCategorySuccess: false,
        createCategoryError: false
      }
    }),
    [createCategory.fulfilled]: state => {
      return {
        ...state,
        createCategory: {
          createCategoryLoading: false,
          createCategorySuccess: true,
          createCategoryError: false
        }
      };
    },
    [createCategory.rejected]: state => {
      return {
        ...state,
        createCategory: {
          createCategoryLoading: false,
          createCategorySuccess: false,
          createCategoryError: true
        }
      };
    }
  }
});

export const { resetCurrentCategoryData, resetCreateCategoryData } = categories.actions;

export default categories.reducer;
