import { get } from 'lodash';

export const fieldTypes = {
  ID: 'ID',
  CURRENCY: 'CURRENCY',
  CHECKBOX: 'MULTIPLE_CHECKBOX',
  TEXT: 'SINGLE_LINE_TEXT',
  SELECT: 'DROPDOWN_SELECT',
  NUMBER: 'NUMBER_FORMATTED',
  DATE: 'DATEPICKER',
  DATETIME: 'DATETIME',
  AUTO: 'AUTOPOPULATED',
  TAGINPUT: 'TAGINPUT',
  SWITCH: 'SWITCH',
  DATE_ACTION: 'DATE_ACTION',
  AUTOCOMPLETE: 'AUTOCOMPLETE',
  AUTOTAGINPUT: 'AUTOTAGINPUT'
};

export const fieldTypeToInputMap = {
  [fieldTypes.TEXT]: 'text',
  [fieldTypes.NUMBER]: 'number',
  [fieldTypes.DATE]: 'date',
  [fieldTypes.DATETIME]: 'date'
};

export const fieldTypeNames = {
  ID: 'ID',
  CURRENCY: 'Currency',
  MULTIPLE_CHECKBOX: 'Checkbox',
  SINGLE_LINE_TEXT: 'Text',
  DROPDOWN_SELECT: 'Picklist',
  NUMBER_FORMATTED: 'Number',
  DATEPICKER: 'Date'
};

export const fieldTypesWithOptions = [fieldTypes.SELECT, fieldTypes.CHECKBOX];

export const numberInputPreventedKeys = [69, 187, 188, 189, 190, 109, 107, 111, 106, 120];

export const updateSxObject = (
  isCustomHandler,
  isAddableItem,
  formObject,
  code,
  index,
  sxObject
) => {
  if (isCustomHandler) {
    if (get(formObject?.errors, code) && get(formObject?.errors, code)?.value) {
      sxObject['& .MuiOutlinedInput-notchedOutline'] = {
        borderColor: get(formObject?.errors, code)?.value ? 'red !important' : 'inherit'
      };
    }
  } else if (isAddableItem) {
    if (get(formObject?.errors, code) && get(formObject?.errors, code)?.options?.length) {
      sxObject['& .MuiOutlinedInput-notchedOutline'] = {
        borderColor: get(formObject?.errors, code)?.options[index]?.value
          ? 'red !important'
          : 'inherit'
      };
    }
  } else {
    if (get(formObject?.errors, code)) {
      sxObject['& .MuiOutlinedInput-notchedOutline'] = {
        borderColor: get(formObject?.errors, code) ? 'red !important' : 'inherit'
      };
    }
  }
  return sxObject;
};
