import { createSlice } from '@reduxjs/toolkit';

import { createQuiz, editQuiz, getQuizByID, getQuizzesList, removeQuiz } from '_api/quizzes';

const initialState = {
  // Get Quizzes
  quizzesList: [],
  quizzesListMeta: {},
  currentQuizData: {},
  getQuizzesLoading: false,
  getQuizzesSuccess: false,
  getQuizzesError: false,
  // Create Quiz
  createQuiz: {
    createQuizLoading: false,
    createQuizSuccess: false,
    createQuizError: false
  },
  // Edit Quiz
  editQuiz: {
    editQuizLoading: false,
    editQuizSuccess: false,
    editQuizError: false
  },
  // Remove Quiz
  removeQuiz: {
    removeQuizLoading: false,
    removeQuizSuccess: false,
    removeQuizError: false
  }
};

const quizzes = createSlice({
  name: 'quizzes',
  initialState,
  reducers: {
    resetCreateQuizData: state => ({
      ...state,
      createQuiz: {
        createQuizLoading: false,
        createQuizSuccess: false,
        createQuizError: false
      }
    }),
    resetEditQuizData: state => ({
      ...state,
      editQuiz: {
        editQuizLoading: false,
        editQuizSuccess: false,
        editQuizError: false
      }
    }),
    resetDeleteQuizData: state => ({
      ...state,
      removeQuiz: {
        removeQuizLoading: false,
        removeQuizSuccess: false,
        removeQuizError: false
      }
    }),
    resetQuizzesListData: state => ({
      ...state,
      quizzesList: [],
      quizzesListMeta: {},
      getQuizzesLoading: false,
      getQuizzesSuccess: false,
      getQuizzesError: false
    }),
    resetCurrentQuizData: state => ({
      ...state,
      currentQuizData: {},
      getQuizzesLoading: false,
      getQuizzesSuccess: false,
      getQuizzesError: false
    })
  },
  extraReducers: {
    // Get quizzes
    [getQuizzesList.pending]: state => {
      return {
        ...state,
        quizzesList: null,
        quizzesListMeta: {},
        getQuizzesLoading: true,
        getQuizzesSuccess: false,
        getQuizzesError: false
      };
    },
    [getQuizzesList.fulfilled]: (state, action) => {
      const { data: quizzesList = null, meta } = action.payload ?? {};

      return {
        ...state,
        quizzesList,
        quizzesListMeta: meta,
        getQuizzesLoading: false,
        getQuizzesSuccess: true,
        getQuizzesError: false
      };
    },
    [getQuizzesList.rejected]: state => {
      return {
        ...state,
        getQuizzesLoading: false,
        getQuizzesSuccess: false,
        getQuizzesError: true
      };
    },
    // Get quiz By ID
    [getQuizByID.pending]: state => {
      return {
        ...state,
        getQuizzesLoading: true,
        getQuizzesSuccess: false,
        getQuizzesError: false
      };
    },
    [getQuizByID.fulfilled]: (state, action) => {
      const { data: currentQuizData = null } = action.payload ?? {};

      return {
        ...state,
        currentQuizData,
        getQuizzesLoading: false,
        getQuizzesSuccess: true,
        getQuizzesError: false
      };
    },
    [getQuizByID.rejected]: state => {
      return {
        ...state,
        getQuizzesLoading: false,
        getQuizzesSuccess: false,
        getQuizzesError: true
      };
    },
    // Create quiz
    [createQuiz.pending]: state => ({
      ...state,
      createQuiz: {
        createQuizLoading: true,
        createQuizSuccess: false,
        createQuizError: false
      }
    }),
    [createQuiz.fulfilled]: (state, action) => {
      const { data: newQuizData = {}, meta: quizzesListMeta } = action.payload ?? {};

      return {
        ...state,
        quizzesList: [...(state.quizzesList || []), newQuizData],
        quizzesListMeta,
        createQuiz: {
          createQuizLoading: false,
          createQuizSuccess: true,
          createQuizError: false
        }
      };
    },
    [createQuiz.rejected]: state => {
      return {
        ...state,
        createQuiz: {
          createQuizLoading: false,
          createQuizSuccess: false,
          createQuizError: true
        }
      };
    },
    // edit quiz
    [editQuiz.pending]: state => ({
      ...state,
      editQuiz: {
        editQuizLoading: true,
        editQuizSuccess: false,
        editQuizError: false
      }
    }),
    [editQuiz.fulfilled]: state => ({
      ...state,
      editQuiz: {
        editQuizLoading: false,
        editQuizSuccess: true,
        editQuizError: false
      }
    }),
    [editQuiz.rejected]: state => ({
      ...state,
      editQuiz: {
        editQuizLoading: false,
        editQuizSuccess: false,
        editQuizError: true
      }
    }),
    // remove quiz
    [removeQuiz.pending]: state => ({
      ...state,
      removeQuiz: {
        removeQuizLoading: true,
        removeQuizSuccess: false,
        removeQuizError: false
      }
    }),
    [removeQuiz.fulfilled]: state => ({
      ...state,
      removeQuiz: {
        removeQuizLoading: false,
        removeQuizSuccess: true,
        removeQuizError: false
      }
    }),
    [removeQuiz.rejected]: state => ({
      ...state,
      removeQuiz: {
        removeQuizLoading: false,
        removeQuizSuccess: false,
        removeQuizError: true
      }
    })
  }
});

export const { resetCurrentQuizData, resetCreateQuizData, resetEditQuizData, resetDeleteQuizData } =
  quizzes.actions;

export default quizzes.reducer;
