import React, { useCallback, useState } from 'react';
import {
  Box,
  FormLabel,
  IconButton,
  InputAdornment,
  TextField as MUITextField,
  Tooltip,
  Typography
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  fieldTypes,
  fieldTypeToInputMap,
  numberInputPreventedKeys,
  updateSxObject
} from '../constants';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { CopyToClipboard } from 'react-copy-to-clipboard/src';
import LightTooltip from 'components/LightTooltip';
import ErrorHandler from '../ErrorHandler';

const TextField = ({
  name,
  code,
  fullWidth,
  sx,
  InputProps,
  isMultiLine,
  isRequired,
  maxLength,
  description,
  value,
  onValueChange,
  disabled,
  directlyPassedErrorMsg,
  field_type: fieldType,
  prefix,
  copyText,
  minimizePadding,
  formObject,
  allowEnter,
  isAddableItem,
  isLabelHided,
  isCustomHandler,
  index,
  error
}) => {
  const [isCopied, setCopied] = useState(false);

  const handleCopied = useCallback(() => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCopied]);

  let sxObject = {
    ...sx,
    '& .MuiInputBase-root': {
      borderRadius: '4px',
      py: isMultiLine ? '10px !important' : ''
    },
    '& .MuiInputBase-input': {
      px: minimizePadding ? '0 !important' : '',
      textOverflow: 'ellipsis !important'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: directlyPassedErrorMsg || error ? 'red !important' : 'none'
    }
  };

  if (isRequired) {
    sxObject['& .MuiInputBase-root'] = {
      ...sxObject['& .MuiInputBase-root'],
      background: 'rgba(69, 63, 145, 0.1)'
    };
  }

  const sxObj = updateSxObject(isCustomHandler, isAddableItem, formObject, code, index, sxObject);

  return (
    <Box width="100%" display="flex" flexDirection="column" alignItems="start">
      {(isLabelHided ? true : !isAddableItem) && (
        <Box width="100%" display="flex" alignItems="flex-end" justifyContent="space-between">
          {name && (
            <FormLabel
              component="p"
              sx={{ fontSize: 14, fontWeight: 700, mb: 1, color: '#8C8C8C' }}
            >
              {name}
            </FormLabel>
          )}
          {description && <LightTooltip title={description} placement="top-start" />}
        </Box>
      )}

      <MUITextField
        name={code}
        fullWidth={fullWidth}
        type={fieldTypeToInputMap[fieldType]}
        value={value?.toString() ?? ''}
        multiline={isMultiLine}
        disabled={disabled}
        inputProps={{
          maxLength
        }}
        InputProps={{
          ...InputProps,
          startAdornment: prefix ? (
            <InputAdornment position="start">{prefix}</InputAdornment>
          ) : null,
          endAdornment:
            copyText !== null ? (
              <CopyToClipboard onCopy={handleCopied} text={copyText}>
                <Tooltip open={isCopied} title="Copied" placement="top-end">
                  <IconButton>
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              </CopyToClipboard>
            ) : null
        }}
        onChange={onValueChange}
        sx={sxObj}
        FormHelperTextProps={{ sx: { mx: 0, my: 1 } }}
        onKeyDown={e => {
          const { keyCode } = e;
          if (fieldType === fieldTypes.NUMBER && numberInputPreventedKeys.includes(keyCode)) {
            e.preventDefault();
            e.stopPropagation();
            return false;
          }

          if (e.key === 'Enter') {
            !allowEnter && e.preventDefault();
          }
        }}
      />
      {directlyPassedErrorMsg ? (
        <Typography variant="p" color="error" fontSize={14} fontWeight={700}>
          {directlyPassedErrorMsg}
        </Typography>
      ) : (
        <ErrorHandler
          formObject={formObject}
          index={index}
          isAddableItem={isAddableItem}
          isCustomHandler={isCustomHandler}
          code={code}
        />
      )}
    </Box>
  );
};
const propTypes = {
  name: PropTypes.string,
  code: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  maxLength: PropTypes.number,
  isMultiLine: PropTypes.bool,
  minimizePadding: PropTypes.bool,
  prefix: PropTypes.string,
  fullWidth: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onValueChange: PropTypes.func,
  field_type: PropTypes.string,
  formObject: PropTypes.object,
  sx: PropTypes.object,
  InputProps: PropTypes.object,
  copyText: PropTypes.string,
  allowEnter: PropTypes.bool,
  isAddableItem: PropTypes.bool,
  isLabelHided: PropTypes.bool,
  isCustomHandler: PropTypes.bool,
  index: PropTypes.number,
  directlyPassedErrorMsg: PropTypes.string,
  error: PropTypes.bool
};

const defaultProps = {
  name: '',
  code: '',
  description: '',
  disabled: false,
  isRequired: false,
  maxLength: null,
  isMultiLine: false,
  minimizePadding: false,
  fullWidth: false,
  allowEnter: false,
  prefix: '',
  value: '',
  onValueChange: noop,
  field_type: '',
  formObject: {},
  sx: {},
  InputProps: {},
  copyText: null,
  isAddableItem: false,
  isLabelHided: false,
  isCustomHandler: false,
  directlyPassedErrorMsg: '',
  index: 0,
  error: false
};

TextField.propTypes = propTypes;
TextField.defaultProps = defaultProps;

export default TextField;
