import axios from 'axios';

import authStorage from 'store/authStorage';

import config from 'config';

const axiosInstance = axios.create({
  baseURL: `${config.backendURL}/v1/admin/`,
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosInstance.interceptors.request.use(function (config) {
  const { accessToken, tokenType } = authStorage.get();
  if (accessToken) config.headers.Authorization = `${tokenType} ${accessToken}`;
  return config;
});

axiosInstance.interceptors.response.use(
  response => {
    return response.data;
  },
  async error => {
    const { response: { data: { details: { code = null } = {} } = {} } = {}, config } = error;

    const { url: requestUrl } = config;
    const isRefreshRequest = requestUrl.includes('/refresh');
    const isLogoutRequest = requestUrl.includes('/logout');
    if (code === 607) {
      const { refreshToken } = authStorage.get();
      try {
        if (isRefreshRequest || isLogoutRequest) {
          return;
        } else {
          const { accessToken, expiresIn, tokenType } = await axiosInstance.post(`/refresh`, {
            token: refreshToken
          });
          if (accessToken) {
            authStorage.set({ accessToken, expiresIn, tokenType });
            return axiosInstance(error.config);
          }
        }
      } catch (err) {
        authStorage.remove();
        window.location.href = '/login';
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
