// assets
import { BookOutlined } from '@ant-design/icons';
import PeopleIcon from '@mui/icons-material/People';
import TranslateIcon from '@mui/icons-material/Translate';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: 'group-dashboard',
  title: '',
  type: 'group',
  children: [
    {
      id: 'books',
      title: 'Books',
      type: 'item',
      url: '/books',
      icon: BookOutlined,
      breadcrumbs: false
    },
    {
      id: 'vocabulary',
      title: 'Vocabulary',
      type: 'item',
      url: '/vocabulary',
      icon: TranslateIcon,
      breadcrumbs: false
    },
    // {
    //   id: 'quizzes',
    //   title: 'Quizzes',
    //   type: 'item',
    //   url: '/quizzes',
    //   icon: QuestionOutlined,
    //   breadcrumbs: false
    // },
    {
      id: 'users',
      title: 'Application Users',
      type: 'item',
      url: '/users',
      icon: PeopleIcon,
      breadcrumbs: false
    }
  ]
};

export default dashboard;
