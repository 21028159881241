import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project import
import Loadable from 'components/Loadable';
import PrivateRoute from 'components/PrivateRoute';
import MainLayout from 'layout/MainLayout';
import VocabularyPage from '../pages/vocabulary';

const BooksPage = Loadable(lazy(() => import('pages/books')));
// const QuizzesPage = Loadable(lazy(() => import('pages/quizzes')));
const UsersPage = Loadable(lazy(() => import('pages/users')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <PrivateRoute>
          <MainLayout />
        </PrivateRoute>
      ),
      children: [
        {
          path: '',
          element: <Navigate to="/books" />
        },
        {
          path: 'books',
          element: <BooksPage />
        },
        {
          path: 'vocabulary',
          element: <VocabularyPage />
        },
        // {
        //   path: 'quizzes',
        //   element: <QuizzesPage />
        // },
        {
          path: 'users',
          element: <UsersPage />
        }
      ]
    }
    // catch all //
    // {
    //   path: '*',
    //   element: <MaintenanceError />
    // }
  ]
};

export default MainRoutes;
