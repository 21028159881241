// action - state management
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'utils/api';

// initial state
export const initialState = {
  userData: [],
  userDataLoadingStatus: {
    type: 'idle'
  }
};

// First, create the thunk
export const getAuthenticatedUser = createAsyncThunk(
  'auth/getAuthenticatedUser',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('/me');

      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

// Then, handle actions in your reducers:
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetUserData: () => {
      return initialState;
    }
  },
  extraReducers: builder => {
    builder.addCase(getAuthenticatedUser.pending.type, state => {
      return {
        ...state,
        userDataLoadingStatus: {
          type: 'loading'
        }
      };
    });

    builder.addCase(getAuthenticatedUser.fulfilled.type, (state, action) => {
      const userData = action.payload;

      return {
        ...state,
        userData,
        userDataLoadingStatus: {
          type: 'success'
        }
      };
    });

    builder.addCase(getAuthenticatedUser.rejected.type, (state, action) => {
      const message = action.payload;

      return {
        ...state,
        userDataLoadingStatus: {
          type: 'error',
          message
        }
      };
    });
  }
});

export const { resetUserData } = authSlice.actions;

export default authSlice.reducer;
