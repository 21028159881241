import React from 'react';
import { Box, FormControl, FormLabel, MenuItem, Select, Typography } from '@mui/material';
import { noop, get } from 'lodash';
import PropTypes from 'prop-types';
import LightTooltip from 'components/LightTooltip';

const SelectField = ({
  name,
  code,
  boxWidth,
  description,
  fullWidth,
  options,
  displayEmpty,
  value,
  disabled,
  isRequired,
  onValueChange,
  formObject,
  label,
  directlyPassedErrorMsg,
  isFilter,
  ...props
}) => {
  let sxObject = {};

  if (isRequired) {
    sxObject['&.MuiInputBase-root'] = {
      ...sxObject['&.MuiInputBase-root'],
      background: 'rgba(69, 63, 145, 0.1)'
    };
  }

  if (get(formObject?.errors, code) || directlyPassedErrorMsg) {
    sxObject['& .MuiOutlinedInput-notchedOutline'] = {
      borderColor:
        directlyPassedErrorMsg || get(formObject?.errors, code) ? 'red !important' : 'inherit'
    };
  }

  return (
    <Box width={boxWidth} display="flex" alignItems="flex-start">
      <FormControl variant="outlined" fullWidth={fullWidth}>
        <Box width="100%" display="flex" alignItems="flex-end" justifyContent="space-between">
          {name && label && (
            <FormLabel
              component="p"
              sx={{ fontSize: 14, fontWeight: 700, mb: 1, color: '#8C8C8C' }}
            >
              {name}
            </FormLabel>
          )}
          {description && <LightTooltip title={description} placement="top-start" />}
        </Box>
        <Select
          labelId={code + '-label'}
          id={code + '-field'}
          name={code}
          value={value?.toString() ?? ''}
          displayEmpty={displayEmpty}
          onChange={onValueChange}
          disabled={disabled}
          fullWidth={fullWidth}
          sx={sxObject}
          {...props}
        >
          {displayEmpty && <MenuItem value="">&nbsp;</MenuItem>}
          {options.map(
            ({ value, label, name, uuid, id, disabled = false, icon = null, hide = false }) => (
              <MenuItem
                key={uuid ?? value ?? id}
                value={id ?? value}
                disabled={disabled}
                sx={{ display: hide ? 'none' : 'inherit' }}
              >
                {icon ?? icon}
                {label && isFilter ? label : name ?? value}
              </MenuItem>
            )
          )}
        </Select>
        {directlyPassedErrorMsg || get(formObject?.errors, code) ? (
          <Typography variant="p" color="red" fontSize={14} fontWeight={700}>
            {directlyPassedErrorMsg ? directlyPassedErrorMsg : 'This field is required'}
          </Typography>
        ) : null}
      </FormControl>
    </Box>
  );
};

const propTypes = {
  name: PropTypes.string,
  code: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  fullWidth: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onValueChange: PropTypes.func,
  formObject: PropTypes.object,
  label: PropTypes.bool,
  isFilter: PropTypes.bool,
  boxWidth: PropTypes.string,
  directlyPassedErrorMsg: PropTypes.string
};

const defaultProps = {
  name: '',
  code: '',
  description: '',
  disabled: false,
  isRequired: false,
  fullWidth: false,
  isFilter: false,
  displayEmpty: true,
  options: [],
  value: '',
  onValueChange: noop,
  formObject: {},
  label: true,
  directlyPassedErrorMsg: '',
  boxWidth: '100%'
};

SelectField.propTypes = propTypes;
SelectField.defaultProps = defaultProps;

export default SelectField;
