import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'utils/api';

export const getSeriesList = createAsyncThunk(
  'series/getSeriesList',
  async (_, { rejectWithValue }) => {
    try {
      return await axiosInstance.get(`/book-series`, {
        data: {}
      });
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSeries = createAsyncThunk(
  'series/createSeries',
  async ({ seriesData = {} }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/book-series`, seriesData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
