export const toBase64 = file =>
  new Promise((resolve, reject) => {
    if (!file) resolve('');
    else if (typeof file === 'string') resolve(file);

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const getFileURL = file => {
  if (file instanceof File) {
    return URL.createObjectURL(file);
  } else return file.name;
};
