import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// scroll bar
import 'simplebar/src/simplebar.css';

// third-party
import { Provider as ReduxProvider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

// apex-chart
import 'assets/third-party/apex-chart.css';

// project import
import App from './App';
import { store } from 'store';

import 'react-toastify/dist/ReactToastify.css';

import './global.css';

const root = createRoot(document.getElementById('root'));

root.render(
  <ReduxProvider store={store}>
    <BrowserRouter basename="/">
      <App />
      <ToastContainer />
    </BrowserRouter>
  </ReduxProvider>
);
