import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { dispatch } from 'store';

import { Box, Button, Grid } from '@mui/material';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from '@mui/x-data-grid';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { getVocabularyItemsList } from '_api/vocabulary';
import { getBooksList } from '_api/books';
import MainCard from 'components/MainCard';
import AddEditVocabularyItemDrawer from 'components/AddEditVocabularyItemDrawer';

import { getVocabularyTableDefaultColumns } from './constants';

const VocabularyPage = () => {
  const {
    vocabularyItemsList = null,
    vocabularyItemsListMeta: { total: totalVocabularyCount = 0 } = {},
    getVocabularyLoading = false
  } = useSelector(state => state.vocabulary || {});
  const { booksList = [] } = useSelector(state => state.books ?? {});
  const columns = getVocabularyTableDefaultColumns(booksList);
  const [currentVocabularyItemData, setCurrentVocabularyItemData] = useState({});
  const [newVocabularyItemDrawerOpen, setNewVocabularyItemDrawerOpen] = useState(false);

  const toggleAddEditVocabularyItemDrawer = ({ row, field } = {}) => {
    if (field === '__check__') return;

    if (row && field) {
      setCurrentVocabularyItemData({ ...row });
    } else setCurrentVocabularyItemData();
    setNewVocabularyItemDrawerOpen(!newVocabularyItemDrawerOpen);
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer
        sx={{
          p: 2,
          flexDirection: 'space-between'
        }}
      >
        <Box flex={1}>
          <GridToolbarQuickFilter debounceMs={1000} />
        </Box>
        <Box>
          <GridToolbarFilterButton />
        </Box>
      </GridToolbarContainer>
    );
  };

  const handleGetVocabularyList = () => dispatch(getVocabularyItemsList());
  const handleGetBooksList = () => dispatch(getBooksList());

  useEffect(() => {
    handleGetVocabularyList();
    handleGetBooksList();
  }, []);

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', p: 0 }}
      >
        <Grid container alignItems="center" justifyContent="space-between" sx={{ m: 0, p: 0 }}>
          <Grid item xs={12} display="flex" alignItems="center" width="100%">
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item>
                <Button
                  variant="contained"
                  color="success"
                  onClick={toggleAddEditVocabularyItemDrawer}
                >
                  New Vocabulary Item
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <MainCard content={false} sx={{ mt: 2, width: '100%', height: '100%' }}>
          <Box sx={{ pt: 1, px: 2, height: '100%' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DataGrid
                rows={vocabularyItemsList ?? []}
                loading={getVocabularyLoading}
                columns={columns}
                onCellClick={toggleAddEditVocabularyItemDrawer}
                rowCount={totalVocabularyCount}
                disableMultipleColumnsSorting
                sortingOrder={['desc', 'asc']}
                rowsPerPageOptions={[15, 25, 50, 100]}
                pagination
                disableSelectionOnClick
                keepNonExistentRowsSelected
                density="compact"
                getRowId={row => row.id}
                slots={{
                  Toolbar: CustomToolbar
                }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                    sx: { py: 2, px: 0, flexDirection: 'row-reverse' }
                  },
                  panel: {
                    sx: {
                      inset: '0px 10% 0px auto !important'
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Box>
        </MainCard>
      </Grid>
      <AddEditVocabularyItemDrawer
        isDrawerOpen={newVocabularyItemDrawerOpen}
        toggleDrawer={() => toggleAddEditVocabularyItemDrawer()}
        vocabularyItemInitialData={currentVocabularyItemData}
        getVocabularyList={handleGetVocabularyList}
      />
    </>
  );
};

export default VocabularyPage;
