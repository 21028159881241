import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'utils/api';

export const getBooksList = createAsyncThunk(
  'books/getBooksList',
  async (_, { rejectWithValue }) => {
    try {
      return await axiosInstance.get(`/books`, {
        params: {
          limit: 1000
        }
      });
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getBookByID = createAsyncThunk(
  'books/getBookByID',
  async ({ bookID = {} }, { rejectWithValue }) => {
    try {
      return await axiosInstance.get(`/books/${bookID}`, {
        data: {}
      });
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createBook = createAsyncThunk(
  'books/createBook',
  async ({ bookData = {} }, { rejectWithValue }) => {
    try {
      let bookPages = structuredClone(bookData?.pages);
      if (bookPages) delete bookData?.pages;

      return await axiosInstance.post(`/books`, bookData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const editBook = createAsyncThunk(
  'books/editBook',
  async ({ bookData = {}, bookID = '' }, { rejectWithValue }) => {
    try {
      let bookPages = structuredClone(bookData?.pages);
      if (bookPages) delete bookData?.pages;

      const response = await axiosInstance.post(`/books/${bookID}`, bookData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const saveBookPage = createAsyncThunk(
  'books/saveBookPage',
  async ({ pageData = {}, bookID = '' }, { rejectWithValue }) => {
    try {
      let requestURL = `/books/${bookID}/pages`;

      if (pageData?.id) {
        requestURL = `/books/${bookID}/pages/${pageData.id}`;
      }

      const response = await axiosInstance.post(requestURL, pageData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const removeBookPage = createAsyncThunk(
  'books/removeBookPage',
  async ({ pageID = '', bookID = '' }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/books/${bookID}/pages/${pageID}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const removeBook = createAsyncThunk(
  'books/removeBook',
  async ({ bookID = '' }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/books/${bookID}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const refreshBookAudioTranscription = createAsyncThunk(
  'books/refreshBookAudioTranscription',
  async ({ bookID = '' }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/books/${bookID}/refresh-audios`);
      toast.success('Audio transcription refreshed successfully');
      return response.data;
    } catch (err) {
      toast.error('Failed to refresh audio transcription');
      return rejectWithValue(err.response.data);
    }
  }
);
