// third-party
import { combineReducers } from 'redux';

// project import
import auth from './auth';
import menu from './menu';
import books from './books';
import vocabulary from './vocabulary';
import categories from './categories';
import series from './series';
import quizzes from './quizzes';
import users from './users';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  auth,
  menu,
  books,
  vocabulary,
  categories,
  series,
  quizzes,
  users
});

export default reducers;
