import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import SelectField from 'components/CustomFormItem/SelectField';

const BookSelectionField = ({ formObject, fieldCode, ...fieldProps }) => {
  const { booksList = [] } = useSelector(state => state.books ?? {});

  const bookOptions = booksList?.map(({ id, title: name }) => ({
    name,
    id
  }));

  return (
    <SelectField
      name="Book *"
      code={fieldCode}
      options={bookOptions}
      value={formObject.values[fieldCode]}
      onValueChange={event => formObject.setFieldValue(fieldCode, event.target.value)}
      formObject={formObject}
      {...fieldProps}
    />
  );
};

const propTypes = {
  formObject: PropTypes.object,
  fieldCode: PropTypes.string
};

const defaultProps = {
  formObject: {},
  fieldCode: ''
};

BookSelectionField.propTypes = propTypes;
BookSelectionField.defaultProps = defaultProps;

export default BookSelectionField;
