const variables = { ...process.env, ...window['variables'] };

const config = {
  defaultPath: '/',
  fontFamily: `'Public Sans', sans-serif`,
  i18n: 'en',
  miniDrawer: false,
  container: true,
  mode: 'light',
  presetColor: 'default',
  themeDirection: 'ltr',
  backendURL: variables.REACT_APP_BACKEND_URL
};

export default config;
export const drawerWidth = 260;
