import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { dispatch } from 'store';

import { useFormik } from 'formik';

import {
  resetCreateVocabularyItemData,
  resetDeleteVocabularyItemData,
  resetEditVocabularyItemData
} from 'store/reducers/vocabulary';
import { vocabularyItemDataValidationSchema } from '../constants';
import { toast } from 'react-toastify';

export const useVocabularyItemForm = ({
  vocabularyItemInitialData = {},
  submitVocabularyItem,
  toggleDrawer,
  getVocabularyItemsList
} = {}) => {
  const {
    createVocabularyItem: {
      createVocabularyItemSuccess = false,
      createVocabularyItemError = false
    } = {},
    editVocabularyItem: { editVocabularyItemSuccess = false, editVocabularyItemError = false } = {},
    removeVocabularyItem: {
      removeVocabularyItemSuccess = false,
      removeVocabularyItemError = false
    } = {}
  } = useSelector(state => state.vocabulary ?? {});

  const vocabularyItemForm = useFormik({
    initialValues: vocabularyItemInitialData,
    validationSchema: vocabularyItemDataValidationSchema,
    onSubmit: submitVocabularyItem,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnMount: false,
    validateOnBlur: false
  });

  useEffect(() => {
    if (createVocabularyItemSuccess || createVocabularyItemError) {
      dispatch(resetCreateVocabularyItemData());
    }

    if (createVocabularyItemSuccess) {
      getVocabularyItemsList();
      vocabularyItemForm.handleReset();
      toggleDrawer();
      toast.success(
        `The vocabulary item “${vocabularyItemForm.values.text}” has been successfully created`
      );
    } else if (createVocabularyItemError) {
      toast.error('Creating the vocabulary item failed. Please try again.');
    }
    //  eslint-disable-next-line
  }, [createVocabularyItemSuccess, createVocabularyItemError]);

  useEffect(() => {
    if (editVocabularyItemSuccess || editVocabularyItemError) {
      dispatch(resetEditVocabularyItemData());
    }

    if (editVocabularyItemSuccess) {
      getVocabularyItemsList();
      vocabularyItemForm.handleReset();
      toggleDrawer();
      toast.success(
        `The vocabulary item “${vocabularyItemForm.values.text}” has been successfully edited`
      );
    } else if (editVocabularyItemError) {
      toast.error('Editing the vocabulary item failed. Please try again.');
    }
    //  eslint-disable-next-line
  }, [editVocabularyItemSuccess, editVocabularyItemError]);

  useEffect(() => {
    if (removeVocabularyItemSuccess || removeVocabularyItemError) {
      dispatch(resetDeleteVocabularyItemData());
    }

    if (removeVocabularyItemSuccess) {
      getVocabularyItemsList();
      vocabularyItemForm.handleReset();
      toggleDrawer();
      toast.success(
        `The vocabulary item “${vocabularyItemForm.values.text}” has been successfully deleted`
      );
    } else if (removeVocabularyItemError) {
      toast.error('Removing the vocabulary item failed. Please try again.');
    }
    //  eslint-disable-next-line
  }, [removeVocabularyItemSuccess, removeVocabularyItemError]);

  return vocabularyItemForm;
};

export default useVocabularyItemForm;
