import { createSlice } from '@reduxjs/toolkit';

import { getUsersList } from '_api/users';

const initialState = {
  // Get Users
  usersList: [],
  usersListMeta: {},
  getUsersLoading: false,
  getUsersSuccess: false,
  getUsersError: false
};

const users = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetCreateCategoryData: state => ({
      ...state,
      createCategory: {
        createCategoryLoading: false,
        createCategorySuccess: false,
        createCategoryError: false
      }
    }),
    resetUsersListData: state => ({
      ...state,
      usersList: [],
      usersListMeta: {},
      getUsersLoading: false,
      getUsersSuccess: false,
      getUsersError: false
    })
  },
  extraReducers: {
    // Get users
    [getUsersList.pending]: state => {
      return {
        ...state,
        usersList: null,
        usersListMeta: {},
        getUsersLoading: true,
        getUsersSuccess: false,
        getUsersError: false
      };
    },
    [getUsersList.fulfilled]: (state, action) => {
      const { data: usersList = null, meta } = action.payload ?? {};

      return {
        ...state,
        usersList,
        usersListMeta: meta,
        getUsersLoading: false,
        getUsersSuccess: true,
        getUsersError: false
      };
    },
    [getUsersList.rejected]: state => {
      return {
        ...state,
        getUsersLoading: false,
        getUsersSuccess: false,
        getUsersError: true
      };
    }
  }
});

export const { resetCurrentCategoryData, resetCreateCategoryData } = users.actions;

export default users.reducer;
