import { Tooltip, Stack, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  arrow: {
    '&:before': {
      border: '1px solid #E6E8ED'
    },
    color: '#FFFFFF'
  },
  tooltip: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #E6E8ED',
    color: '#605E70',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '20px',
    borderRadius: 4
  }
}));

const LightTooltip = ({ title, placement, children, isComponent, style, ...props }) => {
  const classes = useStyles();

  return (
    <Tooltip
      title={title}
      placement={placement}
      arrow
      classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
      style={style}
      {...props}
    >
      {isComponent ? (
        <Stack>{children}</Stack>
      ) : (
        <IconButton>
          <InfoOutlinedIcon sx={{ fontSize: 20 }} />
        </IconButton>
      )}
    </Tooltip>
  );
};

LightTooltip.propTypes = {
  title: PropTypes.string,
  placement: PropTypes.string,
  isComponent: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.object
};

LightTooltip.defaultProps = {
  title: '',
  placement: '',
  isComponent: false,
  children: null,
  style: null
};

export default LightTooltip;
